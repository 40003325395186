import React, { Component } from 'react'
import { Link } from 'gatsby'

import { rhythm } from './../utils/typography'
import style from '../styles/navbar.module.css'

export class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {active: false}
    }

    toggleClass() {
        this.setState({active: !this.state.active});
    };

    render() {

        let navbarMobileBurgerClass = [style.navbarMobileBurger];
        let navbarMobileMenuClass = [style.navbarMobileMenu];
        if(this.state.active) {
            navbarMobileBurgerClass.push(style.change);
            navbarMobileMenuClass.push(style.open);
        }

        return (
            <div className={style.navbar}>
                <div className={style.navbarHeader}>Arts Figuratifs</div>
                <div className={style.navbarBody}>
                    <div style={{ width: rhythm(24) }} >
                        <Link to={'/'}>Accueil</Link>
                        <Link to={'/galeries'}>Galeries</Link>
                        <Link to={'/expos'}>Expositions</Link>
                        <Link to={'/media'}>Média</Link>
                        <Link to={'/contact'}>Contact</Link>
                    </div>
                </div>
                <div className={style.navbarMobile}>
                    <div className={navbarMobileBurgerClass.join(' ')} onClick={this.toggleClass.bind(this)}>
                        <div className={style.bar1} />
                        <div className={style.bar2} />
                        <div className={style.bar3} />
                    </div>
                </div>
                <div className={navbarMobileMenuClass.join(' ')}>
                    <div className={style.mobileLinks}>
                        <Link to={'/'}>Accueil</Link>
                        <Link to={'/galeries'}>Galeries</Link>
                        <Link to={'/expos'}>Expositions</Link>
                        <Link to={'/media'}>Média</Link>
                        <Link to={'/contact'}>Contact</Link>
                    </div>
                </div>
            </div>
        )
  }
}

export default Navbar
