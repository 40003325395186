import React from 'react'

import { rhythm } from '../utils/typography'
import Navbar from './Navbar'
import Footer from './Footer'

import '../styles/global.css'

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0 20px',
        maxWidth: rhythm(45)
      }}>
        <Navbar />
        <div>
          {children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
